import React, { useMemo } from "react";
import { ANSWER_STATUS, FILE_TYPE } from "configs/jobs/constants";
import { withTranslation } from "react-i18next";
import CustomTooltip from "components/Common/CustomTooltip/CustomTooltip.tsx";
import DownloadAllButton from "./DownloadAllButton";

const DownloadAllButtonWrapper = ({
  t,
  realtimeAnswers,
  candidateAnswers,
  handleDownload
}) => {
  const shouldShowDownloadAll = useMemo(() => {
    let isValid = true;
    // eslint-disable-next-line array-callback-return
    Object.keys(realtimeAnswers || {}).map(key => {
      const apiAnswer = candidateAnswers?.find(a => a.key === key);

      if (
      [ANSWER_STATUS.processed, ANSWER_STATUS.failed]?.includes(apiAnswer?.status) ||
      [ANSWER_STATUS.processed, ANSWER_STATUS.failed]?.includes(realtimeAnswers?.[key]?.status) ||
      (ANSWER_STATUS.received && apiAnswer?.is_skipped) ||
      ((realtimeAnswers?.[key]?.status === ANSWER_STATUS.received ||
        realtimeAnswers?.[key]?.status === ANSWER_STATUS.unsafe) &&
        apiAnswer?.question?.answer_type === FILE_TYPE)
      ) {
        return;
      }

      isValid = false;
    });

    return isValid;
  }, [candidateAnswers, realtimeAnswers]);

  return (
    <>
      {
        shouldShowDownloadAll ? (

          <DownloadAllButton
            shouldShowDownloadAll={shouldShowDownloadAll}
            handleDownload={handleDownload}
          />
        ) : (
          <CustomTooltip content={t("job.candidate.download.disabledTooltip")}>
            <DownloadAllButton
              shouldShowDownloadAll={shouldShowDownloadAll}
              handleDownload={handleDownload}
            />
          </CustomTooltip>
        )
      }
    </>
  );
};

export default withTranslation()(DownloadAllButtonWrapper);
